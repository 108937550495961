<template>
  <div class="d-flex flex-nowrap">
    <document-ref-icon
      :visible="!!iconName"
      :icon-name="iconName"
      :mixed="mixed"
      :x-small="_iconProps.xsmall"
      :small="_iconProps.small"
      :normal="_iconProps.normal"
      :large="_iconProps.large"
      :x-large="_iconProps.xlarge"
    />
    <div class="d-flex flex-column flex-nowrap">
      <document-name-ref
        class="nowrap"
        :id="id"
        :show-icon="false"
        :cache-type="cacheType"
      />
    </div>
  </div>
</template>

<script>
import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref'),
    DocumentRefIcon: () => import('@/components/document-ref-icon')
  },
  computed: {
    iconName () {
      return this.id ? `i.${this.toPascalCase(this.id)}` : null
    },
    _iconProps () {
      const _ = Object.assign({
        xsmall: false,
        small: false,
        normal: false,
        large: false,
        xlarge: false
      }, this.iconProps)

      return _
    }
  },
  data () {
    return {
      cacheType: ClientCache.CacheType.ReminderTypeRef
    }
  },
  mixins: [ClientCache],
  props: {
    id: String,
    options: Object,
    mixed: Boolean,
    iconProps: Object,
    showIcon: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.smallText
  font-size 0.75em
</style>
